<template>
	<div class="sales-invoice-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Sales Invoice" filter="invoiceNo" sort="issuedAt"
		               v-bind:order="-1"
		               v-bind:fields="fields"
		               v-bind:data-array="salesInvoices"
		               v-on:add-new="addInvoice"
		               v-on:row-clicked="navigate($event)"></table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";
import { format } from "date-fns"

export default {
	name: "SalesInvoiceTable",
	
	components: {
		TableSummary,
	},
	
	data() {
		return {
			fields: [
				{ display: "Invoice No.", key: "invoiceNo", classBinding: ["f-3"] },
				{ display: "Client", key: "client", classBinding: ["f-5"] },
				{ display: "Status", key: "status", classBinding: [] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Currency", key: "currency", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
				{ display: "Issued At", key: "issuedAt", classBinding: [] },
				{ display: "Issued By", key: "issuedBy", classBinding: ["f-2"] },
			],
		}
	},
	
	computed: {
		salesInvoices() {
			return this.$store.state.contents.salesInvoices.map(invoice => {
				const obj = {...invoice};
				const client = this.$store.state.contents.clients.find(({ _id }) => _id === invoice.client);
				const issuedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === invoice.issuedBy);
				obj.client = client ? `${client.name} (${client.clientNo})` : "N/A";
				obj.issuedBy = issuedBy ? `${issuedBy.preferredName} ${issuedBy.lastName}` : "N/A";
				obj.issuedAt = invoice.issuedAt ? format(new Date(invoice.issuedAt), "yyyy-MM-dd") : "N/A";
				obj.items = invoice.items.length; // type Number
				return obj;
			});
		}
	},
	
	methods: {
		async addInvoice() {
			await this.$router.push("/accounting/sales-invoice/add");
		},
		
		async navigate(dataObj) {
			await this.$router.push(`/accounting/sales-invoice/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>